import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import RolexCategoryBtns from '../../components/preowned/RolexCategoryBtns'

// markup
const RolexDate = () => {
  const data = useStaticQuery(
    graphql`
      query queryRolexDate {
        products: allStrapiProduct(
          filter: { brand: { eq: "Rolex" }, model: { regex: "/Date/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Rolex Datejust Watches for Sale'}
      canonical={'/fine-watches/rolex/rolex-date-datejust-229/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Rolex Datejust Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/search/#search=date">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-rolex-datejust-header.png"
                alt="Pre-Owned Certified Used Rolex Date/DateJust Watches Header"
                aria-label="Used Rolex Date/DateJust Watch Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              ROLEX DATE/DATEJUST: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED ROLEX WATCHES
            </h1>
            <h2>BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED ROLEX DATE/DATEJUST WATCHES</h2>
            <p>
              <br />
              Pre-Owned Used Rolex Date/Datejust Watch
              <br />
              <br />
              Many people say, “I want to sell my watch for cash.” <a href="/">
                grayandsons.com
              </a>{' '}
              and <a href="https://sellusyourjewelry.com/">SellUsYourJewelry.com</a> will buy your
              used watch for cash. Gray and Sons are the best places to go for buying used Rolex
              Date/Datejust watches, selling Rolex Date/Datejust timepieces, and repairing Rolex
              Date/Datejust wristwatches. Rolex Watches Founded in 1905, Rolex is one of the finest
              timepiece brands in the market. Rolex Date and Datejust watches are ultra-luxurious,
              flawlessly combining intricately crafted mechanical movements with impeccable case and
              dial designs. Rolex watches run the gamut from robust sports watches to classic dress
              watches to grand complication timepieces. However, regardless of the specific Rolex
              model (whether men’s Rolex Sports watches or ladies Rolex dress styles), what these
              luxury Swiss timepieces have in common is incredible quality, exquisite finishing, and
              precise timekeeping.
              <br />
              <br />
              <b>Buying and Selling Pre-Owned Used Rolex Date/Datejust Watches</b>
              <br />
              <br />
              Buying Pre-Owned Genuine Rolex Watches The company has long used the slogan “Every
              Rolex tells a story” This declaration perfectly encapsulates what it means to buy a
              Rolex watch. They will indeed last many lifetimes; purchasing a new or used Rolex
              Datejust watch is, in essence, buying a family heirloom. There are many reasons people
              buy pre-owned Rolex Date watches. Sometimes, second-hand Rolex watches are more
              expensive and they are hard to find. However, this isn’t always the case since the
              watch's age and wear take part in its value. Purchasing a second-hand Rolex also opens
              the doors to vintage and discontinued models. Finally, one of the main motivations for
              finding a pre-owned Rolex in the secondary market is that they are sold out at retail.
              Gray &amp; Sons boasts an impressive collection of certified pre-owned Rolex
              date and Datejust watches for sale.
              <br />
              <br />
              Popular Used Rolex Models Pre-owned Date and Datejust, Pre-owned Rolex GMT-Master,
              Rolex Submariner, and Chronograph Pre-owned Rolex Daytona, Explorer, Cellini, and
              Day-Date, Pre-owned Rolex Yacht-Master are all available at Gray and Sons Jewelers. We
              have all the Rolex models you’re looking for! Visit our website to browse through all
              the Rolex watches we have in stainless steel, two-tone, 18k yellow gold, 18k white
              gold, Everose, and platinum. We can find the exact watch you're looking for on an
              oyster bracelet, jubilee bracelet, oyster flex bracelet, and presidential bracelet.
              <br />
              <br />
              Servicing Rolex Watches Given their extraordinary craftsmanship and complicated
              movements, servicing a pre-owned Rolex watch requires great skill and knowledge. As
              such, you should only trust an expert watchmaker to service or repair a Rolex
              timepiece. Gray &amp; Sons Jewelers is proud of its team of skilled watchmakers, each
              expertly trained to handle complex Swiss movements and high-end watches. Our Rolex
              watch repairs and servicing facility is among the best in the business. Many, many
              people near me say, “I want to sell my Rolex watch for cash.” Selling to
              <a href="/">grayandsons.com</a> and{' '}
              <a href="https://sellusyourjewelry.com/">SellUsYourJewelry.com</a> are the best places
              to sell your Rolex watch for cash.
              <br />
              <br />
              Gray &amp; Sons is an independent Rolex dealer, which means better inventory control
              and more price flexibility for customers. Every preowned Rolex Date/Datejust watch for
              sale on Gray &amp; Sons’ website is in stock, ready to ship. Our decision-makers are
              ready to make deals on any in-stock secondhand Explorer men’s watch – or any other
              used Rolex watch available.
              <br />
              <br />
              Conversely, if you’ve wondered, “Where can I sell my used Rolex Date/Datejust watch
              near me?” you can get paid the best cash offer by visiting{' '}
              <a href="https://sellusyourjewelry.com/">SellUsYourJewelry.com</a>
              <br />
              <br />
              <b>
                Gray &amp; Sons is the Best Source for Buying and Selling Pre-Owned Rolex
                Date/Datejust Watches
              </b>
              <br />
              <br />
              <a href="/">Gray &amp; Sons</a> is proud to have served thousands of happy customers
              over the last 40 years at both our physical store, as well as our leading pre-owned
              watch e-commerce website. Many customers have nominated Gray &amp; Sons as the number
              one buyer and seller of pre-owned used Rolex Date/Datejust watches. Chat with an
              expert today. Click <a href="/chat-with-rich">here to talk with a Rolex Specialist</a>{' '}
              today.
              <br />
              <br />
              The Gray &amp; Sons store (located adjacent to the Bal Harbour Shops in
              Surfside/Miami, open six days a week) serves the Miami, Miami Beach, Coral Gables,
              Coconut Grove, South Beach, Bal Harbour, Surfside, Sunny Isles, Ventura, Fort
              Lauderdale, Pinecrest, and Brickle areas. Whether in-store or through the website,
              Gray &amp; Sons watch experts are always ready to help you buy or sell secondhand
              Rolex Date/Datejust watches. Plus, we also have a Gray &amp; Sons independent Rolex
              Date/Datejust repair facility for any watch maintenance needs.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/search/#search=date">
                <button>SHOP ROLEX DATE/DATEJUST WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK ROLEX DATE/DATEJUST WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <h2 className="text-center py-10">EXPLORE MORE GREAT WATCH STORIES FROM GRAY &amp; SONS</h2>
        <RolexCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default RolexDate
